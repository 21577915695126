<script>
import countries from '@shared/assets/data/countries.json';
import euCountries from '@shared/assets/data/eu_countries.json';
import { inArray } from '@shared/config/vuelidate';
import APIStore from '@app/services/API/Store';
import { genericForms } from '@shared/mixins/forms';

export default {
  mixins: [genericForms],
  head: {
    title: 'Entreprise - Paramètres',
  },
  form: {
    name: {
      label: 'Nom de l\'entreprise (Raison sociale) *',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        maxlength: 64,
        hasCounter: false,
        required: true,
      },
    },
    address_line_1: {
      label: 'Adresse (numéro + voie) *',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        maxlength: 200,
        hasCounter: false,
        required: true,
      },
    },
    address_line_2: {
      label: 'Complément d\'adresse',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        maxlength: 200,
        hasCounter: false,
      },
    },
    postal_code: {
      label: 'Code postal *',
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        maxlength: 32,
        hasCounter: false,
        required: true,
      },
    },
    city: {
      label: 'Ville *',
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        maxlength: 64,
        hasCounter: false,
        required: true,
      },
    },
    state: {
      label: 'Région / État',
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        maxlength: 64,
        hasCounter: false,
      },
    },
    country_code: {
      label: 'Pays',
      type: 'select',
      options: countries,
      column: 'is-6',
      errors: ['inArray'],
      inputAttrs: {
        placeholder: 'Select a name',
        expanded: true,
      },
    },
    type: {
      label: 'Forme juridique *',
      type: 'select',
      column: 'is-6',
      options: {
        'micro-entrepreneur': 'Micro-entrepreneur / Auto-entrepreneur',
        EI: 'EI (Entreprise individuelle)',
        EIRL: 'EIRL',
        EURL: 'EURL',
        SARL: 'SARL',
        SASU: 'SASU',
        SAS: 'SAS',
        SA: 'SA',
        SNC: 'SNC',
        SCP: 'SCP',
        'N/A': 'Autre',
      },
      inputAttrs: {
        placeholder: 'SASU/SAS/EURL/SARL/EI/Micro entreprise...',
        maxlength: 32,
        hasCounter: false,
        required: true,
      },
    },
    siret: {
      label: 'Siret *',
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        maxlength: 32,
        hasCounter: false,
        required: true,
      },
    },
    nda: {
      label: 'Numéro de Déclaration d\'Activité',
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        maxlength: 32,
        hasCounter: false,
      },
      tooltip: 'Ce champ doit obligatoirement être renseigné si vous comptez générer des certificats de réalisation ou des certificats d’assiduité pour vos apprenants',
    },
    intracom_vat: {
      label: 'TVA intracommunautaire',
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        maxlength: 32,
        hasCounter: false,
      },
    },
  },
  data() {
    return {
      countries,
      initialLoading: false,
      company: {
        name: '',
        type: '',
        siret: '',
        nda: '',
        intracom_vat: '',
        address_line_1: '',
        address_line_2: '',
        postal_code: '',
        city: '',
        state: '',
        country_code: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  validations: {
    company: {
      country_code: { inArray: inArray(Object.keys(countries)) },
    },
  },
  created() {
    this.initialLoading = true;
    APIStore.getCompany(this.authStore.uuid)
      .then((data) => {
        Object.assign(this.company, data.data);

        if (!this.company.country_code) {
          this.company.country_code = 'FR';
        }
      })
      .finally(() => (this.initialLoading = false));
  },
  methods: {
    action() {
      this.isLoading = true;
      return APIStore.updateCompany(this.authStore.uuid, this.authStore.store_company_id, this.company);
    },
    onCountryChange() {
      const hasToFillVAT = (
        !this.company.intracom_vat
        && this.company.country_code !== 'FR'
        && euCountries.includes(this.company.country_code)
      );

      if (hasToFillVAT) {
        this.$buefy.dialog.alert({
          title: 'Attention !',
          message: `
            <p>
              Vous avez sélectionné un état membre de l'Union européenne (UE).
              Merci de renseigner votre <strong>numéro de TVA intracommunautaire</strong>.
            </p>
          `,
        });
      }
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Dites-nous tout sur votre entreprise
      </h2>
      <p>
        Pour la gestion de votre facturation, nous avons besoin de vos informations légales (raison sociale, siret, adresse, etc)...<br>
      </p>
      <p>Ces informations <strong>sont privées et ne seront pas communiquées</strong> à vos apprenants.</p>
    </div>

    <div class="column is-relative">
      <b-skeleton v-if="initialLoading" height="480" />
      <form v-else class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-if="k != 'country_code'" v-model="company[k]" :v="$v.company[k]" :field="field" />
            <BaseField
              v-else
              v-model="company[k]"
              :v="$v.company[k]"
              :field="field"
              @change.native="onCountryChange"
            />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
